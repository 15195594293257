.post {
  img {
    display: block;
    margin: 0 auto;
    max-width: 80%;
    max-height: 600px;
  }
  figcaption {
    color: #757575;
    text-align: center;
    font-style: italic;
  }
}
